import { Link, useNavigate } from "react-router-dom";
import CustomNotification from "./CustomNotification";
import { useEffect, useState } from "react";
import { getAllNotifications } from "../services/notificationApi";

const Header = ({ tableNo, restaurant }) => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    const interval = setInterval(() => {
      fetchNotifications();
    }, 3000);

    // Clear the interval when the component is unmounted or the dependencies change
    return () => clearInterval(interval);
  }, []);
  const fetchNotifications = async () => {
    if (restaurant && tableNo) {
      // const query = `?restaurant=${restaurant?._id}`;
      const query = `?restaurant=${restaurant}&table=${tableNo}&isSeen=false`;
      try {
        const res = await getAllNotifications(query)
        setNotifications(res);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <header className=" w-full h-[54px] flex items-center bg-[#F28500] z-20 py-4 border-b shadow-sm border-gray-100">
      <div className="container-max flex justify-between items-center">
        <div className="flex items-center gap-2 md:gap-4">
          <Link className="">
            <svg
              width="26"
              height="36"
              viewBox="0 0 26 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <rect width="26" height="36" fill="url(#pattern0_194_100)" />
              <defs>
                <pattern
                  id="pattern0_194_100"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlinkHref="#image0_194_100"
                    transform="matrix(0.0111111 0 0 0.00802469 0 0.138889)"
                  />
                </pattern>
                <image
                  id="image0_194_100"
                  width="90"
                  height="90"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEQ0lEQVR4nO2dTYiWVRTHf41J2ow25VibxqAW46B9rHKVJZEpgmahJYgbR9tFm6CCRARRW2WbyEULiWqKEMGNX1AiiKaTLSodNcrGSShFMOxD6MjF42ZonPfjueee53nuD/4wDPO+zzn/ue997nPuxwuZTCaTyWQydaYXWAVsAgaBb4FzwGXgH9Vl/d0Q8Jn+7Sv62sw4TAIWAjuAM4C0qfAeHwLPAR3jXbROzAK2ASMFmDuewntv1WvVjoeB7cDfEQ0eq3+BncBsakCntq7rhgaP1XX9J0+norwEXEho8P91KcupEHdpCxKnCt3JVErOQzo0E+caKvOwsB8478BEaVCjwGOUjHnAJQfmSZMKMT9JSZhTUpNFdaUMLftB4BcHZkkBI5Jwf3E7uhhyYJIUpO+AKTjkAwfmSMF6H4cPI1JRLcMJ0yIXhSSxfgW6cMB7DsyQyHo3tcl9iQtEYlj5eySl0R85MEGMFCYlktCrU0qpDRDDVp1kbL3NQfJirC3WJnfo3VhqphGd3zRjoYOkJZGetTR6h4OEJZHCE7AZZ42T2wsM6HCyUxUmWdcB+4xjOW1l8izDpE4BTzUQ03w1wCquUKWMziqjZL4CupuI617ga6PYXsaATUYtubuF2ILZwwbxbcSAQYNEnmkjvvkG8X2CAbGL+/sKiPFA5BiPY8DPkZNYW0CM6yPH+BMG/BE5ib4CYpwdOcbfMSB2IamrgBi7IscYFmdG568SGD09cozXMOBiCbqO/sgx/oYBpyInMVBAjK9GjvEHDDgaOYn9BcR4MHKMRzDg08hJCLCgjfieNojvYwx40yCRc0BPC7HdV9Bmo4n0BgYsNkhEtEAUahfNmHzIKLbnMeAB4D+jhIYbrHssMGrJornfjxHHjZIS1QF9rO7XcXaX/rze4MY3Vscw5B3j5MSR3rI0+lEHCUsihU+SKcccJC3G+oYErHaQuBgrbOo3Z3LNFtGMaM5JeN2BAWKk10jIZONpfkmkH1O25lssdWCERFZ4GnbBLgdmSCR9gSN6tBguFdMoMANnLDGsgYiBQi6LcIrFKiYx0gYcc4fRSiaJrC81F9d06lSPlFSHgbspCfckKKVKATrZ5GSDC2bqxnUpkcmtTJ+5oFs/iuJcRz0O41rps/c4MHM87S5TnzwRk/S8O3Gm7dbb2axYA/zpwOCrWk+vNH1640ll8vfAXGrClESHDu6sUn/cDMsNVqeKFrxeoOZ061nPsUz+vMzj4xis1FPOizI4vNeK1El5pbegXV8nynzWqBU9bW7KDAvlc1fRIE+0eFp62F/zeKMXydxkSwtGb9bXZpo8T2+0CZMvVvk4+dgMGG8yqi13NrjsLOlyrarwdgNGh301mTbpmWC37rUqFO29MHgbo8N2vExBvHgboyv1XSqpmapn7481+UoVvkPFGxvLtpqorHSosedVYWdY/gq9TCZDhbkB9a4D7gPF080AAAAASUVORK5CYII="
                />
              </defs>
            </svg>
          </Link>
          <div className=" font-bold text-black">Table no. {tableNo}</div>
        </div>

        <div className=" flex gap-5 md:hidden">
          <CustomNotification notifications={notifications} />

          {/* <Link to="/cart">
            <svg
              width="27"
              height="30"
              viewBox="0 0 27 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <rect width="27" height="30" fill="url(#pattern0_194_102)" />
              <defs>
                <pattern
                  id="pattern0_194_102"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlinkHref="#image0_194_102"
                    transform="matrix(0.0111111 0 0 0.01 0 0.05)"
                  />
                </pattern>
                <image
                  id="image0_194_102"
                  width="90"
                  height="90"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJElEQVR4nO2cy4uOURzHPzMYl1zLJblksLFzzYIVCpuh4W+QWFhKUZiSwUKIUsjCXmjKZYPZToaYaZQyKJemiXKZcTs6dU69TaaZd+Y8z/nNOb9PfTfv2/uc9/fp6bmcGyiKoiiKoiiKoihVUwtsBI4AN4EOoBf45dLrPrPfHQY2uN8ow2QO0AS8A0yVeQscB2YPt7EcqXNn5rcRCB6Yr8Ahd0ylguXAkwCCB6YNqK9sKGfWu+utKSg9wDoyZzXwpUDJPp+BlWTK/BHe8EaaN8A8MuROiZJ9bpEZuyNI9tlJJowHXkYU3QmMIwMaI0r2aSADWgSItveHpJkB9AsQ3QdMJ2EaBUj22UHCnBIg2OcECdMiQHAW1+lOAYJ9npMwvQIEV3Y2JUufAME+P0iYnwIE+9j/kiw9AgT7fCJhXgkQ7GP7W5LlsQDBPg9JmEsCBPtcIGH2CxDss5eEWStAsM8qEqYW+ChA8geghsS5IUD0dTKgUYDohlzGDN9HvmxMIBPORBR9moxYHGlIy/ZvLCEzrkQQfZlMZ5D2lyjZtrWUTGkuUXTSY4RDMQV4XdIEx6lkznbgb4GS7bG3xS4yh8e95tjFSaKuoL7qRzm9nFQzXaw98HSCWcNuPTMWBhS9IHYx0jGBogyBii4Jo2e0ik4Ko2e0ik4Ko2d08WwOKNoeSxmkJ68toOg2d0ylArtC6kEBfR33gWmVDeVKjVuuXOTie7srza4cJsz8j7nAvpLXtHS4Nm3byXcY7QFuu82nTKT8AVqBg8AyEsEOhh5whRU5ijKavACOAmsYY6wATgJdAiSaKtPl/rutQSQ1bk5dqwBZJlBaXU1ibqJbgacCxJiC0u5qjMZM4JoAEaakXHU1l77x1DMBxZuSYx9HF5X5JNEtoGgTKd1lTC2bGLg/wozR2HvSpCJFnxNQpBGSs0VJro/8RmeE5XdRb5YXBRRnhOV8aMm2X/e7gMKMsFgnk0OK3iKgKCM0m0KKbhJQkBGaYyFF3xNQkBGauyFFp9yXYUYZu5N7MMrc59mMsdhlG8HQJw4GFW3dKIqiKIqiKIqiMGb5BzafOwTQDlQbAAAAAElFTkSuQmCC"
                />
              </defs>
            </svg>
          </Link> */}
        </div>
      </div>
    </header>
  );
};
export default Header;
