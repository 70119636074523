import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: JSON.parse(localStorage.getItem("cart")) || [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const {
        _id,
        quantityUnit,
        quantityQuantity,
        itemPrice,
        quantityOptionId,
      } = action.payload;
      /*
      don't use price use itemPrice and _id is not and unique identifier because we are adding same item with different with quantityQuantity , quantityUnit , itemPrice we need to match for uniquely identifying
      */
      const existingItemIndex = state.items.findIndex(
        (cartItem) =>
          cartItem?.item?._id === _id &&
          cartItem.quantityUnit === quantityUnit &&
          cartItem.quantityQuantity === quantityQuantity &&
          cartItem.itemPrice === itemPrice
      );

      if (existingItemIndex !== -1) {
        state.items[existingItemIndex].quantity += 1;
      } else {
        state.items.push({
          item: action.payload,
          quantity: 1,
          quantityOption: { quantityUnit, quantityQuantity, price: itemPrice },
          quantityUnit,
          quantityQuantity,
          price: itemPrice,
          quantityOptionId,
          itemPrice,
        });
      }

      localStorage.setItem("cart", JSON.stringify(state.items));
    },
    removeFromCartMultiple: (state, action) => {
      const { _id, quantityUnit, quantityQuantity, itemPrice } = action.payload;

      const existingItemIndex = state.items.findIndex(
        (cartItem) =>
          cartItem?.item?._id === _id &&
          cartItem.quantityUnit === quantityUnit &&
          cartItem.quantityQuantity === quantityQuantity &&
          cartItem.itemPrice === itemPrice
      );

      if (existingItemIndex !== -1) {
        if (state.items[existingItemIndex].quantity > 1) {
          state.items[existingItemIndex].quantity -= 1;
        } else {
          // Remove the item if quantity becomes 0
          state.items.splice(existingItemIndex, 1);
        }
      }

      localStorage.setItem("cart", JSON.stringify(state.items));
    },

    removeFromCart: (state, action) => {
      state.items = state.items.filter(
        (cartItem) => cartItem?.item?._id !== action.payload.id
      );
      localStorage.setItem("cart", JSON.stringify(state.items));
    },

    increaseItemQuantity: (state, action) => {
      const { id } = action.payload;

      const itemToIncrease = state.items.find(
        (cartItem) => cartItem?.item?._id === id
      );

      if (itemToIncrease) {
        itemToIncrease.quantity += 1;
        localStorage.setItem("cart", JSON.stringify(state.items));
      }
    },
    decreaseItemQuantity: (state, action) => {
      const { id } = action.payload;
      const itemToDecrease = state.items.find(
        (cartItem) => cartItem?.item?._id === id
      );

      if (itemToDecrease) {
        if (itemToDecrease.quantity > 1) {
          itemToDecrease.quantity -= 1;
        } else {
          state.items = state.items.filter(
            (cartItem) => cartItem?.item?._id !== id
          );
        }
        localStorage.setItem("cart", JSON.stringify(state.items));
      }
    },
    clearCart: (state) => {
      state.items = [];
      localStorage.removeItem("cart");
    },
  },
});

export const selectItemsInCart = ({ cart }) => cart?.items;

export const selectTotalPrice = ({ cart }) => {
  return cart?.items.reduce((total, cartItem) => {
    return total + cartItem.item.itemPrice * cartItem.quantity;
  }, 0);
};

export const selectTotalUniqueItemCountInCart = ({ cart }) => {
  const uniqueItemIds = new Set(
    cart.items.map((cartItem) => cartItem.item._id)
  );
  return uniqueItemIds.size;
};

export const {
  addToCart,
  removeFromCart,
  increaseItemQuantity,
  decreaseItemQuantity,
  clearCart,
  removeFromCartMultiple,
} = cartSlice.actions;

export default cartSlice.reducer;
