import { useSelector, useDispatch } from "react-redux";
import {
  addToCart,
  decreaseItemQuantity,
  increaseItemQuantity,
  removeFromCart,
  removeFromCartMultiple,
  selectItemsInCart,
} from "../features/cart/cartSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const CartItemList = () => {
  const cartItems = useSelector(selectItemsInCart);
  const dispatch = useDispatch();
  const removeItem = (id) => dispatch(removeFromCart({ id }));
  const decreaseQuantity = (id) => dispatch(decreaseItemQuantity({ id }));
  const increaseQuantity = (id) => dispatch(increaseItemQuantity({ id }));
  const [restaurant, setRestaurant] = useState("");
  const [tableNo, setTableNo] = useState("");
  const navigate = useNavigate();
  const getSearchParams = (url) => {
    const params = new URLSearchParams(url);
    const restaurant = params.get("restaurant");
    const tableNo = params.get("tableNo");
    return { restaurant, tableNo };
  };

  useEffect(() => {
    const { restaurant: restaurantFromUrl, tableNo: tableNoFromUrl } =
      getSearchParams(location.search);
    if (!restaurantFromUrl || !tableNoFromUrl) {
      navigate({
        pathname: location.pathname,
        search: `?restaurant=${restaurant}&tableNo=${tableNo}`,
      });
    } else {
      setRestaurant(restaurantFromUrl);
      setTableNo(tableNoFromUrl);
    }
  }, [location.search, navigate, restaurant, tableNo]);
  if (cartItems.length === 0) {
    return (
      <div className="flex grow min-h-[60vh] justify-center items-center">
        <p>Your cart is empty!</p>
      </div>
    );
  }

  const handleAddToCart = (item) => {
    dispatch(addToCart(item));
    toast.success("Added to cart!");
  };

  const handleRemoveFromCart = (item) => {
    dispatch(removeFromCartMultiple(item));
    toast.success("Item removed");
  };
  const handleAddDifferentPrice = (selectedItem) => {
    handleAddToCart(selectedItem);
  };
  const handleRemoveDifferentPrice = (selectedItem) => {
    handleRemoveFromCart(electedItem);
  };

  return (
    <ul className="basis-7/12">
      {cartItems?.map((item) => (
        <li
          key={item?.item?._id}
          className="flex gap-4 justify-between max-w-[600px] my-4"
        >
          <div className="basis-3/12">
            <img
              className="w-full h-full md:h-auto object-cover block rounded-md aspect-square"
              src={item?.item?.thumbnail}
              alt=""
            />
          </div>
          <div className="basis-9/12">
            <p className="text-lg lg:font-semibold">
              {item?.item?.name} ({item?.quantityQuantity} {item?.quantityUnit})
            </p>

            <p className="hidden md:block">
              {item?.item?.description?.length > 50
                ? item?.item?.slice(0, 50) + "..."
                : item?.item?.description}
            </p>

            <p className="my-2 space-x-1 text-sm">
              <span className="font-semibold">
                ₹
                {parseFloat(
                  (item?.quantity * parseFloat(item?.item?.itemPrice)).toFixed(
                    2
                  )
                )}
              </span>
              <span className="text-gray-800 text-sm">
                ({item?.item?.itemPrice} × {item?.quantity})
              </span>
            </p>

            {/* actions */}
            <div className="flex justify-between items-center mt-2">
              <div className="flex items-center">
                <button
                  onClick={() => handleRemoveFromCart(item?.item)}
                  // disabled={item?.item?.quantity === 1}
                  className={
                    "bg-orange-500 disabled:bg-orange-500/50 disabled:cursor-not-allowed text-white lg:font-bold w-6 h-6 rounded-md"
                  }
                >
                  -
                </button>
                <p className="lg:font-bold w-8 h-8 flex justify-center items-center">
                  {item?.quantity}
                </p>
                <button
                  onClick={() => {
                    handleAddDifferentPrice(item.item);
                  }}
                  className="bg-orange-500 text-white lg:font-bold w-6 h-6 rounded-md"
                >
                  +
                </button>
              </div>

              {/* <button
                onClick={() => removeItem(item?.item?._id)}
                className="border border-orange-500 text-xs lg:font-semibold text-orange-500 p-1 px-2 rounded-md"
              >
                Remove
              </button> */}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default CartItemList;
