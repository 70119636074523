import { Box, HStack, Radio } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getCustomerOrderReceipt, submitRating } from "../services/orderApi";
import {
  capturePayment,
  getRestaurantSecrets,
  payForOrder,
  payForOrderInCash,
} from "../services/paymentApi";
import { showRazorpayPaymentModal } from "../utils/checkout";
import { getErrorMessage } from "../functions";

const PayOrder = ({ totalAmount, customerOrder, setCustomerOrder }) => {
  const navigate = useNavigate();
  const [paymentType, setPaymentType] = useState("online");
  const [restaurant, setRestaurant] = useState("");
  const [tableNo, setTableNo] = useState("");
  const [isPaid, setIsPaid] = useState(false);
  const [rating, setRating] = useState(customerOrder.customerRating);
  const [hover, setHover] = useState(null);

  const getSearchParams = (url) => {
    const params = new URLSearchParams(url);
    const restaurant = params.get("restaurant");
    const tableNo = params.get("tableNo");
    return { restaurant, tableNo };
  };

  useEffect(() => {
    const { restaurant: restaurantFromUrl, tableNo: tableNoFromUrl } =
      getSearchParams(location.search);
    if (!restaurantFromUrl || !tableNoFromUrl) {
      navigate({
        pathname: location.pathname,
        search: `?restaurant=${restaurant}&tableNo=${tableNo}`,
      });
    } else {
      setRestaurant(restaurantFromUrl);
      setTableNo(tableNoFromUrl);
    }
  }, [location.search, navigate, restaurant, tableNo]);

  const paymentSuccessHandler = async () => {
    try {
      await capturePayment({
        restaurantOrderId: customerOrder._id,
        waiterTip: customerOrder.waiterTip || 0,
      });
      // setCustomerOrder({});
      setIsPaid(true);
      // setTimeout(() => {
      //   navigate("/");
      // }, 10000);
      return toast.success("💸 Payment Success 💸", {
        position: "top-center",
      });
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const paymentFailedHandler = async () => {
    toast.error("Payment Failed");
  };

  const checkoutHandler = async () => {
    // if (user?.restaurant?.isSubscriptionActive) return;
    const query = `?type=razorpay&restaurant=${restaurant}`;
    const restaurantPaymentSecrets = await getRestaurantSecrets(query);
    try {
      const order = await payForOrder({
        amount: totalAmount,
        restaurant,
        restaurantOrderId: customerOrder._id,
      });
      showRazorpayPaymentModal(
        order,
        paymentSuccessHandler,
        paymentFailedHandler,
        restaurantPaymentSecrets.secretData
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const provideRating = async () => {
    try {
      const res = await submitRating(rating, `?id=${customerOrder._id}`);
      setCustomerOrder(res);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };
  const checkoutPayCashHandler = async () => {
    try {
      const res = await payForOrderInCash({
        amount: totalAmount,
        restaurant,
        restaurantOrderId: customerOrder._id,
        waiterTip: customerOrder.waiterTip || 0,
      });
      localStorage.setItem("paymentType", "cash");
      setPaymentType("cash");
      setCustomerOrder({ ...customerOrder, ...res.updatedOrder });
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };
  useEffect(() => {
    const paymentType = localStorage.getItem("paymentType");
    if (paymentType === "cash") {
      setPaymentType("cash");
    } else {
      setPaymentType("online");
    }
  }, []);

  const handleDownloadPDF = async () => {
    try {
      const query = `?restaurant=${restaurant}&table=${tableNo}`;
      await getCustomerOrderReceipt(query);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  return (
    <div className="flex justify-start p-2 flex-col gap-2 mt-5">
      <div className="flex w-full gap-2 justify-center items-center">
        {!isPaid && !customerOrder.isCashPaymentRequested && (
          <button
            className="mt-3 w-full pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1 h-10"
            variant="primary"
            colorScheme="blue"
            size="sm"
            onClick={checkoutHandler}
          >
            Pay Online
          </button>
        )}
        {!isPaid && !customerOrder.isCashPaymentRequested ? (
          <button
            className="mt-3 w-full pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1 h-10"
            variant="primary"
            colorScheme="blue"
            size="sm"
            onClick={checkoutPayCashHandler}
          >
            Pay Cash
          </button>
        ) : (
          <p>
            You have requested for cash payment. Waiter will collect the cash
          </p>
        )}
      </div>
      {(isPaid || (!isPaid && paymentType === "cash")) && (
        <>
          {customerOrder.customerRating ? (
            <div className="flex flex-col items-center gap-2 border border-r-2 p-3 mt-3 mb-3">
              <p className="font-semibold">Thanks for your rating!</p>
              <div className="font-semibold flex items-center">
                <HStack
                  spacing={"2px"}
                  className="ml-3 flex items-center justify-center"
                >
                  {[...Array(5)].map((star, index) => {
                    const ratingValue = index + 1;
                    return (
                      <Box
                        as="label"
                        key={index}
                        color={
                          ratingValue <= customerOrder?.customerRating?.rating
                            ? "#ffc107"
                            : "#e4e5e9"
                        }
                        _disabled={true}
                      >
                        <FaStar cursor={"pointer"} size={20} />
                      </Box>
                    );
                  })}
                </HStack>
              </div>
              <p className="font-light">
                {customerOrder?.customerRating?.feedback}
              </p>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-2 border border-r-2 p-3 mt-3 mb-3">
              <div className="flex flex-col ">
                <p className="font-semibold">
                  Did you enjoy your meal? Rate us!
                </p>
                <div className="flex flex-col justify-start mt-4">
                  <HStack
                    spacing={"2px"}
                    className="mb-5 flex items-center justify-center"
                  >
                    {[...Array(5)].map((star, index) => {
                      const ratingValue = index + 1;
                      return (
                        <Box
                          as="label"
                          key={index}
                          color={
                            ratingValue <= (hover || rating?.rating)
                              ? "#ffc107"
                              : "#e4e5e9"
                          }
                          onMouseEnter={() => setHover(ratingValue)}
                          onMouseLeave={() => setHover(null)}
                        >
                          <FaStar
                            cursor={"pointer"}
                            size={30}
                            transition="color 200ms"
                            onClick={(e) =>
                              setRating({ ...rating, rating: ratingValue })
                            }
                          />
                        </Box>
                      );
                    })}
                  </HStack>
                  <div>
                    <p className="text-sm  text-gray-600">Feedback</p>
                    <textarea
                      value={rating?.feedback}
                      onChange={(e) =>
                        setRating({ ...rating, feedback: e.target.value })
                      }
                      rows={3}
                      className="w-full p-2 mt-2 text-sm bg-gray-100 rounded-md resize-none outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Write your feedback here"
                    ></textarea>
                  </div>
                </div>
              </div>
              <button
                className="mt-3 w-full pl-2 pr-2 p-1 text-white rounded-[10px] bg-blue-500 font-semibold flex justify-center items-center gap-1 h-10"
                variant="primary"
                colorScheme="blue"
                size="sm"
                onClick={provideRating}
              >
                Submit Rating
              </button>{" "}
            </div>
          )}
          <div>
            <p>
              Bill paid successfully! You will be automatically redirected to
              dashboard in 10secs
            </p>
            <button
              className="mt-3 w-full pl-2 pr-2 p-1 text-white rounded-[10px] bg-slate-800 font-semibold flex justify-center items-center gap-1 h-10"
              variant="primary"
              colorScheme="blue"
              size="sm"
              onClick={handleDownloadPDF}
            >
              Download Receipt
            </button>{" "}
          </div>
        </>
      )}
    </div>
  );
};

export default PayOrder;
