import React, { useState } from "react";
import { MdClose } from "react-icons/md";

const CustomPopup = ({ notifications }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <div className="relative inline-block text-left z-50">
      <div>
        <button
          onClick={togglePopup}
          type="button"
          className="text-2xl text-black mr-3 outline-none relative bg-transparent border-none"
          style={{ padding: "0.25rem", cursor: "pointer" }}
        >
          <svg
            width="27"
            height="30"
            viewBox="0 0 27 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <rect width="27" height="30" fill="url(#pattern0_511_825)" />
            <defs>
              <pattern
                id="pattern0_511_825"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use
                  xlinkHref="#image0_511_825"
                  transform="matrix(0.0111111 0 0 0.01 0 0.05)"
                />
              </pattern>
              <image
                id="image0_511_825"
                width="90"
                height="90"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEg0lEQVR4nO2d34sXVRjGn03XTS8iKELyZ6ZZYmaFeGUXrrchhNdr/QHbEolEhOSFulaIUYqpiDfhhVApiXcVEqQVUSyICFJm39Isf62L24W8ceR8YV3mzJz3nDMz7+y8H3hgWXaeOedhnDnnPWdGQFEURVEURVEURYniQQBDAM4AuG11GsDrAPrirJUucwH8AoAc+tn+jRJ5JeeFPDFsvbIjGPIIuavBmBO1nTOMoL+ru7FNZpQRtPlbJYBeRshdmWMUT5YCGAZwOSDofwF8AmCF78nayGIAxwLCdekLAE/W3SlpQ7jtAMYThtzVuPU252g1jzNHFqH6CcB8tJTVAP6sIOSuOgBWoWUsB3C9wpC7uglgJVrCHAC/1xDyxCt7AYTTE3l8n2ftomyZ2siMmrPIxDy19wIYA3AHwAEAswJ83hUQcldbAtpv+nzQZmBKtR+nLmZ9mNHQfUyPZQD+ExDwxKHfM8w+7Mvw2Y2E99SsgG4x//l8KSDcyTrOaH+Po/Yyboep0WxxNPIaw+MpAHcFBDtZd+2M1JcbDp93EMkDAH51mJv7tC8fCQjVJXNb9OWgw+NC7MOxP6eBz3t6TKtpzOyra7aNPryY47M2Iud7I40s0xGGxyoBYRbpBUZ/XMNTMwIJxjWxeJPhsVlAkEXaxOjPJofHRQSyOKdh5uHmyzEBQRbpc2a93OWzKCBnvOow+43p84OAIIv0PbNPFx0+A0g0SeGONvIaRYJkRlYcDjl8diGAkw6zjUyfMQFBFslMpzm85vA5gQDOO8y4NV1qiLi19CyPcwjgb4fZY0wfaog4zHZ4XEEArgIQt1pFDRGHPoeHqXuw0aDdzEwZtN46Krp16MOwoodhquEdNUS1De9STVioIaptwpJiCj5NQIDkKd9SaV6xbSB1UWmJp8fDAgIkTz3k2aenczyeQE1l0nkCAiRPmbXRmDIpt9h2H3scpqb47cNzAgIkTz3r2aeRMgr/sUtZGwQESJ56pc6lrLzF2f0ex78tIEDy1Fse/TlQ1uJs3nYDsxO0iCMCAiRPferRn8tlbTfI20DjE3SV23IpUpc8+vNXmRtoDO9nnGBHxDCIhKpo2Dqcccx7SEivPUnHXqXmdYXpBce8ISA4YmrQI4cd9sru2J+LciidHwUER0yZF/kbxRIBoVGgzHaCxuAqSFEDFFQYqoNHmK8Wk8AV8UfRALYJCIsitRXCmW+vCGq4xgAshGA+ExASJdJRCGW9gHAosV6GMMw3jf4REAyVsEldzDuJZrb0rYBQqCSdkvAdEFMaPCwgDKqgsmdKxrXxgYAQqCKZF54qxbzau7NhJVBKpI4trsW+3uxFVqmwbRquIug2Xsk0SaZMWjodAR2lmvVHFUHrrQOFq0tJmDFhtYVapk6VD0NFURRFUZSpwWiiDwkqBZxLEPTZopMocG6N5Yj7qbhWsi5B0FGbwtvEVxEhf1N345vEIgBXAz89z/mOnQLgJbsKzQl5jSYXviv1a4+Qza1Gr+QE9Nv/jeKsHWeP2p/N7/TBpygKpij/A26bsJ2Atn1xAAAAAElFTkSuQmCC"
              />
            </defs>
          </svg>

          <span className="bg-red-500 text-white rounded-full px-1.5 py-0.5 absolute top-0 right-0 -mt-1 -mr-1 text-xs">
            {notifications.filter((n) => !n.isSeen).length}
          </span>
        </button>
      </div>
      {isPopupOpen && (
        <div className="origin-bottom-end absolute right-0 mb-2 w-[70vw] md:w-[50vw] lg:w-[30vw] bg-white text-black rounded shadow-lg">
          <div className="p-4">
            <div className="flex justify-between items-center">
              <div className="font-semibold text-lg">Notifications</div>
              <button
                onClick={togglePopup}
                className="p-2 hover:bg-gray-200 rounded-full"
              >
                <MdClose />
              </button>
            </div>
            {/* <div className="bg-white h-1 w-5 transform rotate-45 absolute top-0 right-4"></div> */}
            <div className="mt-2 max-h-96 overflow-y-auto no-scrollbar">
              {notifications.map((notification, index) => (
                <div
                  key={index}
                  className={`mb-2 pb-2 p-1 ${
                    index === notifications.length - 1
                      ? "border-b-0"
                      : "border-b"
                  } ${!notification.isSeen && "bg-sky-100"}`}
                >
                  <div className={`text-sm font-semibold `}>
                    {notification.title}
                  </div>
                  <div className="w-full text-xs text-gray-500 flex justify-between items-center">
                    <div className="text-md">{notification.description}</div>
                    <div>
                      {new Date(notification.createdAt).toLocaleString()}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {notifications.length === 0 && (
              <div className="flex justify-center items-center w-full">
                <h1
                  variant="primary"
                  className="text-center text-xl font-semibold"
                >
                  No notifications
                </h1>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomPopup;
