import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCart,
  selectItemsInCart,
  selectTotalPrice,
} from "../features/cart/cartSlice";
import { createOrder } from "../services/orderApi";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { getErrorMessage } from "../functions";

const OrderSummary = (props) => {
  const cartItems = useSelector(selectItemsInCart);
  const totalPrice = useSelector(selectTotalPrice);
  // const discount = totalPrice * 0.1;
  // const deliveryCharges = totalPrice * 0.05;
  // const totalAmt = totalPrice + deliveryCharges - discount;
  const totalAmt = totalPrice;
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const dispatch = useDispatch();
  const [restaurant, setRestaurant] = useState("");

  const [tableNo, setTableNo] = useState("");
  const [instructions, setInstructions] = useState("");

  const getSearchParams = (url) => {
    const params = new URLSearchParams(url);
    const restaurant = params.get("restaurant");
    const tableNo = params.get("tableNo");
    return { restaurant, tableNo };
  };

  useEffect(() => {
    const { restaurant: restaurantFromUrl, tableNo: tableNoFromUrl } =
      getSearchParams(location.search);
    if (!restaurantFromUrl || !tableNoFromUrl) {
      navigate({
        pathname: location.pathname,
        search: `?restaurant=${restaurant}&tableNo=${tableNo}`,
      });
    } else {
      setRestaurant(restaurantFromUrl);
      setTableNo(tableNoFromUrl);
    }
  }, [location.search, navigate, restaurant, tableNo]);

  const handlePlaceOrder = async () => {
    try {
      // Perform any necessary actions for placing the order

      // Set showConfirmation to true to display the order confirmation
      setShowConfirmation(true);

      // You may want to reset showConfirmation after a certain duration or user action
      // For example:
      // setTimeout(() => {
      //   setShowConfirmation(false);
      // }, 5000); // Reset after 5 seconds

      const res = await createOrder({
        cartItems,
        table: tableNo,
        restaurant,
        customer: { id: Math.random() },
        instructions,
      });

      dispatch(clearCart());
      props.setOrderDetail(res);
      props.setShowConfirmation(true);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    const { restaurant: restaurantFromUrl, tableNo: tableNoFromUrl } =
      getSearchParams(location.search);
    if (!restaurantFromUrl || !tableNoFromUrl) {
      navigate({
        pathname: location.pathname,
        search: `?restaurant=${restaurant}&tableNo=${tableNo}`,
      });
    } else {
      setRestaurant(restaurantFromUrl);
      setTableNo(tableNoFromUrl);
    }
  }, [location.search, navigate, restaurant, tableNo]);

  return (
    <div className="basis-5/12 h-fit sticky top-40 p-4 my-8 md:m-0">
      {/* <h2 className="text-md font-bold border-b pb-4">Order Summary</h2> */}

      <div className="py-4 border-b w-100">
        <div className="flex justify-between items-center text-lg md:text-2xl w-100">
          <h1>Total Amount</h1>
          <h1 className="text-orange-500">
            ₹ {parseFloat(totalAmt).toFixed(2)}
          </h1>
        </div>
      </div>

      <div className="flex flex-col justify-start mt-4">
        <p className="text-sm  text-gray-600">Instructions</p>
        <textarea
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          rows={3}
          className="w-full p-2 mt-2 text-sm bg-gray-100 rounded-md resize-none outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Special instructions for the food (Optional)"
        ></textarea>
      </div>

      {!showConfirmation && (
        <button
          className="w-full block mt-4 uppercase text-lg bg-orange-600 text-white text-center p-2 rounded-md"
          onClick={handlePlaceOrder}
        >
          Place order
        </button>
      )}
    </div>
  );
};

export default OrderSummary;
