function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = (err) => {
      reject(err);
    };
    document.body.appendChild(script);
  });
}

export const showRazorpayPaymentModal = async (
  order,
  onSuccess,
  onFailed,
  secrets
) => {
  const RAZORPAY_CHECKOUT_URL = "https://checkout.razorpay.com/v1/checkout.js";
  const RAZORPAY_KEY_ID = secrets.apiKey;
  await loadScript(RAZORPAY_CHECKOUT_URL);

  const option = {
    key: RAZORPAY_KEY_ID,
    currency: order.currency,
    amount: order.amount,
    order_id: order.id,
    name: "QR Restaurant",
    handler: onSuccess,
  };

  const rzp = new window.Razorpay(option);

  rzp.on("payment.failed", onFailed);

  rzp.open();
};
