import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectTotalUniqueItemCountInCart } from "../features/cart/cartSlice";
const FootNav = () => {
  const totalItems = useSelector(selectTotalUniqueItemCountInCart);

  return (
    <header className="fixed bottom-0 left-0 w-full p-1.5 bg-[#F28500] z-20  border-t shadow-sm border-gray-100 rounded-xl ">
      <div className="container-max flex justify-center items-center h-full">
        <ul className="text-zinc-700 flex justify-center space-x-20">
          <li>
            <Link to={"/"}>
              {totalItems > 0 && (
                <span className="absolute top-0.5 left-1/2 bg-red-500 text-white font-semibold rounded-full px-1 text-xs">
                  {totalItems}
                </span>
              )}
              <svg
                width="35"
                height="35"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <rect width="35" height="35" fill="url(#pattern0_194_127)" />
                <defs>
                  <pattern
                    id="pattern0_194_127"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                  >
                    <use
                      xlinkHref="#image0_194_127"
                      transform="scale(0.0111111)"
                    />
                  </pattern>
                  <image
                    id="image0_194_127"
                    width="90"
                    height="90"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACuUlEQVR4nO2cPY8NYRiGr2RlURDaJSoKOpr1D2joJRQalY9Sq3PwC1SKFT0Vf8BHY1UKKuKjFDZBQtwyyUzC2D1md96Z93mfea7kbs7ZM3Pf93nznvPsTA4EQRAEQRAEf7MDuAF8AD4Cs/qxICFLwFNALT0HDkTTaTgKvFmn5EbvgeUoux/ngG9zSm5U/c3Zjsdc/GMLar9hs/r5ybAA3OpQcFs369fOY/afY1TPT4JdwP0tlNzoIbBnzvHbK7mt6oPWPQeBlz1KbvQKOLzBObq83jUngE8JSm70BTi1znkmXfQF4EfCkhv9BK62zjXJorcDdwYouK17wM6pFr20wRAylJrhZlJFL9ffWzWyup7TBWeArxlK3oyKZqHDsGBFRQ8hDwwU6LroQ4mGkCh6xCFEsaL/5Uo9MKhAFUF11WPFQFnqIfNXbsYeQjSQTF+5OQa8NVCSEqn6d+pxjFHCEKIt6DtwHgOUNISoh24D23KVvLuwIUQ99QjYO3bJJQ4hSqDXwJGxSj5Z6BCihFduTg9dcslDiBLqF3BtiIKrex3uGggoY1pJfR/IdQOhZFRVN8nIcTVEBQ02UTTDF/0uZdFTGEpkYetYrMuOLYQ8N0VqYspG7uCKovOXoljRZC8stg5sK/ZooujsqzBWNPmLi62D/KW636On4M1VGBn25iqMDHtzFUaGvbkKI8PeXIWRYW+uwsiwN1dhZNibqzAy7M1VGBn25iqMDHtzFUaGvbkKI8PeXIWRYW+uwsiwN1dhZNibqzAy7M1VGBn25iqMDHtzFUaGvbkKI8PeXIWRYW+uwsiwN1dhZNjbplkbKJAM6nPOop8ZKEAj6UnOoi8bKEAj6WLu3xZ9YaAEDaxVC7+Uvt952avAPoxQrexL9T7m4QNyDXhcbxfZV3IQBEEQBEEQBEEQBAHG+A35uRv+u/0KggAAAABJRU5ErkJggg=="
                  />
                </defs>
              </svg>
            </Link>
          </li>
          <li>
            <Link to={"/cart"}>
              <svg
                width="35"
                height="35"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <rect width="35" height="35" fill="url(#pattern0_194_126)" />
                <defs>
                  <pattern
                    id="pattern0_194_126"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                  >
                    <use
                      xlinkHref="#image0_194_126"
                      transform="scale(0.0111111)"
                    />
                  </pattern>
                  <image
                    id="image0_194_126"
                    width="90"
                    height="90"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC9ElEQVR4nO2cvWsUYRDGf0bMoWAQSSOxNlrYRq1SCP4FKtai2CRpIoKNoKZXUUFJYWxFFNJYqZ0xwa9a7SL4gd93EUTjK4tvkcL3krvb3ZmdnR88cEVgZh7udt/ZZwk4juM4juNIsAm4CnwFQkKfgPvACWCjSJcGuNbG4P/pNTAi3XTV6AN+dGh0piVgn3TzdTA60xtgs/QAli8dK3Vauvkqkd3cLgNfujD6pXTzllgPHGlj9i7pBq3xJGH0SenGrHE2YfRD6casMZIw+hewRbo5a0fBdwmzD0s3Z40bCaNvSjdmjYMJoz/Eb7yTEwPAz4TZe/Mq4vzjQcLo825QvkwmjH6Wc53aM5ww+g+wvfbu5MyrhNnH8y5Udy728MQvGFGWOl0A+os0+oCCQYMSZWYXRgNoKhgyKNBHCuaOgiGDAn0u2uhjCoYMCjRbtNHb4pEu1FzjlMBTBYMGYWV7ReGcUzBoENQiJbFHwbBBUNMawoBQA5UaeMwoGDgIaBkYLNPoQwqGDgJaQFEYEAxrCkVhQDCsUU1hQDCqVnzeUzo7FQwfLK3d3YQBwaBKWbtTXFJgQLC0dtc9DFhEmOzm8F2BEcHK2t2OuwqMCAVLxXuG1sOA5bLX7rqGAQsownIYMIUiLIcBoyjCahjQklq76xYGzKKQGQXGmFq76xQGDKOQAWNhgPjaXZcwYBrFTCowyNTanWKHAoPyUHYJ3IpyHikwqlfdpgLsV2BUL/oN7KYiXFdgWLc6Q4XYANxSYFqnugKso2L0xVPIkgID1/IG/1EqziBwKp6x38cH6Rquw2+Be8CY/0Mux3Ecx3GcbmgAE8DjmMW14ufxgnM5qboiDAEv2pxrn8e/sVJXhMYqw64cumGgrhgTHWxsYwbqijHfwcBzBuqK0exg4KaBumI0Oxj4m4G6Ysz7paMcxoVuSlJ1xWjEI9Rajln9BuqKMrTK0EUuLBJ1RemPP9G5eKNqxlcTxgr+RknVdRzHcRzHcRzHQRV/ASvoI7knhlT4AAAAAElFTkSuQmCC"
                  />
                </defs>
              </svg>
            </Link>
          </li>
          <li>
            <Link to="/invoice">
              <svg
                width="35"
                height="35"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <rect width="35" height="35" fill="url(#pattern0_194_128)" />
                <defs>
                  <pattern
                    id="pattern0_194_128"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                  >
                    <use
                      xlinkHref="#image0_194_128"
                      transform="scale(0.0111111)"
                    />
                  </pattern>
                  <image
                    id="image0_194_128"
                    width="90"
                    height="90"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAADhklEQVR4nO3cS2wNURzH8W9L1aOhFl7xioqQiHQhQT0S6UbCAokNsSASC0EjIiQWHrGwZeG5EQkRQVjYeKeLVkVURYgmEvHWpN6P0nJk6hClt3Pu3Hn8753/J/l30U5n7vwynTnnzDkFpZRSSimllFJKKaWUUgnrC9QADcBHwOR5eedwHVgPlCLEKKBJQDhR1W17jolfyU0Cwogj7ESv7BoBIcRV65IMukFAAHFVfZJBfxAQQFzlnWuo+gCzgDXAAeA8cAd4CLQCr4Hv9uAqS/2AZTbUbJpnylF/YIu9UoP8OSkHc4DHOd63lI9NQHsIDwiVQW9gf4hPYtWNcuCyY4BvgLPAVmCpvc2MBwbbB+dvJmXlqwK457Cji8B828RzYVJWPaoCWnx28NIGnC2TsspoCfDZYcBkTICQEXDiIoKu+asHl6lOAwMIzqSsuvDur0ccfmkPUJxDyKQ5aK9VcMVn46/ACsJhUlZ/Whb3fTb0utpzQwqZNAbttSxe+WzUDEwkXCZlRZvPBhdshyVsJmXV4w8PASURhIyAExcRdAewmWiZlNV/33gfsKeXRNAdtjk6zY6Pe+366bb52SY56EfAlBhCJoQPfg6YRGaVwBOJQdcBw4iPCViNQLXjMSoFXdmdX47bCS1xMlnWM2BlgB7pXilBbwOKiJ9xrE/AjhzGVWZICTopxqd+ACeBsTkep0xAyGKDrrc91jCUCwhZXNDNwKKQj1MlIGQxQbfaMfAoeqH7BISceNDfgIPAkIiOMdUO7aY66DPAhAj374X8XEDAiQcdpeUCl2kUlFI7zmGE1TsKyDjgpoBQxU1ED9MCO/faCK215LlewC7bizRCqzGL2VsiDQUuCQjSL+SR5LGZwFMBQWZar1Jnbxd5fSWvduiEtNtXcUmMSua9MuCEwxX11C5YUgF4r6zuOoR8FRge5ACKzhVffr08r9Wx27ZCVES9vLfA4mx3rn4ZbXtTfiHfsvMGVQDVDnMBvTr6z5oY5ajINsn8JsB/AVa57lR1VeEwN9urBzFO8CkoJcBGO6XAL+RTwMCkP3C+GWTfEbosc9ZenoNiO81sMjAP2AnUZvEez5udNJsCYYTWNWAEBSTpQE03t4rthdjLM4Kq1s4ALUhGQN0AFhb6sGZS4bYAh0OcYydeHK/aX9h/I3kM2GCXP+S68lYppZRSSimllFJKKaUUeeMnKwwu2fgoLkAAAAAASUVORK5CYII="
                  />
                </defs>
              </svg>
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default FootNav;
