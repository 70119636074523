import { useEffect, useState } from "react";
import { getCustomerOrder } from "../services/orderApi";
import { useNavigate } from "react-router-dom";
import PayOrder from "../components/PayOrder";
import { IoReturnUpBackOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import { getErrorMessage } from "../functions";

const Invoice = () => {
  const navigate = useNavigate();
  const [restaurant, setRestaurant] = useState("");
  const [tableNo, setTableNo] = useState("");
  const [customerOrder, setCustomerOrder] = useState({});
  const [tip, setTip] = useState(0);
  const [loading, setLoading] = useState(true);

  const getSearchParams = (url) => {
    const params = new URLSearchParams(url);
    const restaurant = params.get("restaurant");
    const tableNo = params.get("tableNo");
    return { restaurant, tableNo };
  };

  useEffect(() => {
    const { restaurant: restaurantFromUrl, tableNo: tableNoFromUrl } =
      getSearchParams(location.search);
    if (!restaurantFromUrl || !tableNoFromUrl) {
      navigate({
        pathname: location.pathname,
        search: `?restaurant=${restaurant}&tableNo=${tableNo}`,
      });
    } else {
      setRestaurant(restaurantFromUrl);
      setTableNo(tableNoFromUrl);
    }
  }, [location.search, navigate, restaurant, tableNo]);

  const fetchCustomerOrder = async () => {
    try {
      const query = `?table=${tableNo}&restaurant=${restaurant}&isPaid=false`;
      const res = await getCustomerOrder(query);
      setCustomerOrder(res[0]);
    } catch (err) {
      toast.error(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!tableNo || !restaurant) return;
    fetchCustomerOrder();
  }, [tableNo, restaurant]);

  useEffect(() => {
    setCustomerOrder({
      ...customerOrder,
      waiterTip: tip,
    });
  }, [tip]);

  const totalAmt = customerOrder?.bill?.totalAmount + tip;

  const statusObj = {
    reordered: { color: "#2196F3" }, // Blue
    completed: { color: "#FF5252" }, // Red
    ongoing: { color: "#4CAF50" }, // Green
    cooking: { color: "#FFC107" }, // Yellow
    preparing: { color: "#009688" }, // Teal
    withWaiter: { color: "#2ACABC" },
    inKitchen: { color: "#2ddeB3" },
    pending: { color: "#4CAF50" },
    undefined: { color: "transparent" },
  };

  const handleRedirect = () => {
    navigate("/");
  };

  if (loading) {
    return (
      <div className="container-max">
        <div className="h-[1vh] text-center animate-pulse rounded-lg bg-gray-100 mt-5"></div>
        <div className="mt-3 h-40 w-full animate-pulse rounded-lg bg-gray-100"></div>
        <div className="mt-3 h-40 w-full animate-pulse rounded-lg bg-gray-100"></div>
        <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8 mt-5">
          {Array.from({ length: 3 }).map((_, i) => (
            <div
              key={i}
              className=" h-10 w-full animate-pulse rounded-lg bg-gray-100"
            ></div>
          ))}
        </div>
        <div className="mt-5 h-[2vh] w-full animate-pulse rounded-lg bg-gray-100"></div>
        <div className="mt-5 h-[2vh] w-full animate-pulse rounded-lg bg-gray-100"></div>
        <div className="mt-5 h-[2vh] w-full animate-pulse rounded-lg bg-gray-100"></div>
        <div className="mt-5 h-[2vh] w-full animate-pulse rounded-lg bg-gray-100"></div>
        <div className="mt-3 h-20 w-full animate-pulse rounded-lg bg-gray-100"></div>
        <div className="mt-3 h-10 w-full animate-pulse rounded-lg bg-gray-100"></div>
      </div>
    );
  }

  if (!customerOrder) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-xl text-center">No order has been placed yet</h1>
        <button
          className="mt-4 px-2 py-1 text-sm font-semibold text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
          onClick={handleRedirect}
        >
          Order some food?
        </button>
      </div>
    );
  }

  return (
    <div className="basis-5/12 h-fit scroll-auto p-4 rounded-md  md:m-0 pb-12">
      <div className="flex gap-2 justify-between">
        <div className="flex gap-2 items-center">
          <IoReturnUpBackOutline size={34} onClick={() => navigate("/cart")} />
          <h2 className="text-xl">Order Details</h2>
        </div>
        <h2
          className={`text-sm text-white px-2 py-1 rounded-md capitalize`}
          style={{
            backgroundColor:
              statusObj?.[customerOrder?.status?.toLowerCase()]?.color,
            opacity: 0.8,
          }}
        >
          {customerOrder?.status}
        </h2>
      </div>

      <div className="py-4 text-lg space-y-4">
        <p className="text-gray-600 text-sm mt-3">Items:</p>
        {customerOrder?.cartItems?.map((item) => (
          <div key={item.item._id} className="flex justify-between text-sm">
            <div className="flex items-center gap-1 rounded">
              <img
                src={item.item.thumbnail}
                className="w-10 aspect-square rounded"
              />
              <p className="font-medium">{item.item.name}</p>
            </div>
            <div className="flex items-center">
              <p className="font-medium">
                {item.quantity} x ₹ {item.item.itemPrice}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Price details */}
      {/* <div className="py-4 text-lg space-y-4 border-b">
        <div className="flex justify-between items-center font-semibold">
          <p className="font-normal">
            Price ({customerOrder?.cartItems?.length} items)
          </p>
          <p>₹ {parseFloat(totalAmt).toFixed(2)}</p>
        </div>
        <div className="flex justify-between items-center font-semibold">
          <p className="font-normal">Discount (10%)</p>
          <p> - ₹ {parseFloat(discount).toFixed(2)}</p>
        </div>
        <p className="text-sm my-2">
          You'll save ₹{parseFloat(discount).toFixed(2)} on this order 🎉
        </p>
      </div> */}

      {/* Total amount */}

      <div className="py-5 w-100 text-gray-600">
        <p className="text-md text-black">Instructions:</p>
        <p className="text-sm rounded ">{customerOrder?.instructions}</p>
      </div>

      {!customerOrder.isCashPaymentRequested && (
        <div className="flex flex-col items-center gap-2 mt-2 bg-white p-2 rounded-lg border border-gray-200">
          <p className="font-semibold text-sm">Add a tip</p>
          <div className="flex gap-4 flex-wrap">
            {[10, 20, 30, 50, 100].map((amount) => (
              <div
                key={amount}
                className="text-sm bg-gray-100 rounded p-2 cursor-pointer"
                onClick={() => {
                  if (tip === amount) {
                    setTip(0);
                    return;
                  }
                  setTip(amount);
                }}
                style={{
                  backgroundColor: tip === amount ? "orange" : "",
                  color: tip === amount ? "white" : "",
                }}
              >
                ₹{amount}
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="py-4 border-b w-100">
        {customerOrder?.waiterTip > 0 && (
          <>
            <div className="flex justify-between items-center text-lg md:text-2xl w-100">
              <p className="text-sm text-gray-500">Bill Amount</p>
              <p className="text-gray-500 text-sm">
                ₹ {customerOrder.bill.totalAmount}
              </p>
            </div>
            <div className="flex justify-between items-center text-lg md:text-2xl w-100">
              <p className="text-sm text-gray-500">Waiter tip</p>
              <p className="text-gray-500 text-sm">
                ₹ {customerOrder.waiterTip}
              </p>
            </div>
          </>
        )}
        <div className="flex justify-between items-center text-lg md:text-2xl w-100 mt-5">
          <h1>Total Amount</h1>
          <h1 className="text-orange-500">
            ₹ {parseFloat(totalAmt).toFixed(2)}
          </h1>
        </div>
      </div>

      <PayOrder
        totalAmount={totalAmt}
        customerOrder={customerOrder}
        setCustomerOrder={setCustomerOrder}
      />
    </div>
  );
};

export default Invoice;
