import { useEffect, useState } from "react";
import useRestaurants from "../hooks/useRestaurants";
import RestaurantList from "./RestaurantList";

const Body = () => {
  const { restaurants, isLoading } = useRestaurants();
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);

  useEffect(() => {
    setFilteredRestaurants(restaurants);
  }, [isLoading]);

  return (
    <div className="bg-white relative ">
      {/* banners */}
      {/* <BannerList banners={banners} isLoading={isLoading} /> */}

      {/* food list */}
      {/* <FoodList foods={foods} isLoading={isLoading} /> */}

      {/* search bar */}

      {/* restaurant list */}

      <RestaurantList isLoading={isLoading} restaurants={filteredRestaurants} />
    </div>
  );
};
export default Body;
