import http from "./http";

const restaurantApi = "/restaurant/order";

export const createOrder = (data, query = "") => {
  return http.post(`${restaurantApi}/addToCart${query}`, data);
};

export const submitRating = (data, query = "") => {
  return http.put(`${restaurantApi}/rating/submit${query}`, data);
};

export const getCustomerOrder = async (query = "") => {
  const res = await http.get(`${restaurantApi}/customer/all${query}`);
  return res;
};

export const downloadPdf = async (data) => {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `https://bill-backend-ecru.vercel.app/api/invoice/pdf/download`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );
  return response;
};

export const getCustomerOrderReceipt = async (query = "") => {
  try {
    const res = await http.get(`${restaurantApi}/customer/receipt${query}`);
    console.log(res.html);

    const response = await downloadPdf({
      html: res.html,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Create a blob from the response
    const blob = await response.blob();

    // Create a temporary anchor element to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "invoice.pdf"); // or any other filename you prefer
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error during the receipt download process:", error);
  }
};
