import RestaurantCard from "./RestaurantCard";
import { useEffect, useRef, useState } from "react";
import {
  getAllCategories,
  getChefSpecial,
  getTopRated,
} from "../services/menuApi";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryCard from "./CategoryCard";
import { getErrorMessage } from "../functions";
import toast from "react-hot-toast";

const RestaurantList = () => {
  const [loading, setLoading] = useState(true);
  const [itemsByCategory, setItemsByCategory] = useState({});
  const [chefSpecial, setChefSpecial] = useState({});
  const [filteredFoodItems, setFilteredFoodItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [restaurant, setRestaurant] = useState("");
  const [tableNo, setTableNo] = useState("");

  const getSearchParams = (url) => {
    const params = new URLSearchParams(url);
    const restaurant = params.get("restaurant");
    const tableNo = params.get("tableNo");
    return { restaurant, tableNo };
  };

  useEffect(() => {
    const { restaurant: restaurantFromUrl, tableNo: tableNoFromUrl } =
      getSearchParams(location.search);
    if (!restaurantFromUrl || !tableNoFromUrl) {
      navigate({
        pathname: location.pathname,
        search: `?restaurant=${restaurant}&tableNo=${tableNo}`,
      });
    } else {
      setRestaurant(restaurantFromUrl);
      setTableNo(tableNoFromUrl);
    }
  }, [location.search, navigate, restaurant, tableNo]);

  const fetchChefSpecial = async () => {
    const res = await getChefSpecial(`?restaurant=${restaurant}`);
    const groupedById = res.categories.reduce((acc, category) => {
      acc[category._id] = acc[category._id] || [];
      acc[category._id].push(category);
      return acc;
    }, {});
    setChefSpecial(groupedById);
  };
  const fetchTopRated = async () => {
    const res = await getTopRated(`?restaurant=${restaurant}`);
    const groupedById = res.categories.reduce((acc, category) => {
      acc[category._id] = acc[category._id] || [];
      acc[category._id].push(category);
      return acc;
    }, {});
    setItemsByCategory(groupedById);
  };
  useEffect(() => {
    (async () => {
      try {
        if (restaurant)
          await Promise.all([fetchChefSpecial(), fetchTopRated()]);
      } catch (e) {
        toast.error(getErrorMessage(error));
      } finally {
        setLoading(false);
      }
    })();
  }, [restaurant]);

  const fetchCategories = async () => {
    const res = await getAllCategories(`?restaurant=${restaurant}`);

    setCategories(res);
  };
  useEffect(() => {
    try {
      if (restaurant) fetchCategories();
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  }, [restaurant]);

  const searchRef = useRef();

  const handleSearch = (e) => {
    e.preventDefault();

    const searchTerm = searchRef.current.value.toLowerCase();
    if (!searchTerm) return;
    const filteredItems = [];

    // Filter items from itemsByCategory
    Object.keys(itemsByCategory).forEach((categoryName) => {
      itemsByCategory[categoryName].forEach((menu) => {
        const filteredMenus = menu.menus.filter(
          (item) =>
            (item.isSpecial && item.name.toLowerCase().includes(searchTerm)) ||
            item.category.toLowerCase().includes(searchTerm) ||
            item.description.toLowerCase().includes(searchTerm)
        );
        if (filteredMenus.length > 0) {
          filteredMenus.forEach((filteredItem) => {
            if (
              !filteredItems.some(
                (existingItem) => existingItem.menus[0]._id === filteredItem._id
              )
            ) {
              filteredItems.push({
                categoryName,
                menuId: menu._id,
                menus: [filteredItem],
              });
            }
          });
        }
      });
    });

    // Filter items from chefSpecial
    Object.keys(chefSpecial).forEach((categoryName) => {
      chefSpecial[categoryName].forEach((menu) => {
        const filteredMenus = menu.menus.filter(
          (item) =>
            (item.isSpecial && item.name.toLowerCase().includes(searchTerm)) ||
            item.category.toLowerCase().includes(searchTerm) ||
            item.description.toLowerCase().includes(searchTerm)
        );
        if (filteredMenus.length > 0) {
          filteredMenus.forEach((filteredItem) => {
            if (
              !filteredItems.some(
                (existingItem) => existingItem.menus[0]._id === filteredItem._id
              )
            ) {
              filteredItems.push({
                categoryName,
                menuId: menu._id,
                menus: [filteredItem],
              });
            }
          });
        }
      });
    });

    setFilteredFoodItems(filteredItems);
  };

  return (
    <div className="pb-16">
      <form
        onSubmit={handleSearch}
        className="flex gap-2 md:gap-4 max-w-[560px] w-[90%] mx-auto mt-6"
      >
        <input
          type="search"
          name="search"
          id="search"
          placeholder="Search ..."
          className="p-2 px-4 rounded-md border outline-none focus-within:border-orange-400 border-gray-200 grow w-full"
          ref={searchRef}
          disabled={loading}
        />
        <button
          disabled={loading}
          type="submit"
          className="bg-orange-400 basis-2/12 text-center text-white p-2 flex justify-center gap-2 items-center md:px-8 rounded-md text-sm md:text-base"
        >
          <MagnifyingGlassIcon className="w-4 h-4" />{" "}
          <span className="hidden md:block">Search</span>
        </button>
      </form>

      {loading ? (
        <>
          <div className="container-max">
            <div className="h-[1vh] text-center animate-pulse rounded-lg bg-gray-100 mt-5"></div>
            <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8 mt-5">
              {Array.from({ length: 3 }).map((_, i) => (
                <div
                  key={i}
                  className="h-40 w-full animate-pulse rounded-lg bg-gray-100"
                ></div>
              ))}
            </div>
          </div>
          <div className="container-max">
            <div className="h-[1vh] text-center animate-pulse rounded-lg bg-gray-100 mt-5"></div>
            <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8 mt-5">
              {Array.from({ length: 3 }).map((_, i) => (
                <div
                  key={i}
                  className="h-40 w-full animate-pulse rounded-lg bg-gray-100"
                ></div>
              ))}
            </div>
          </div>
          <div className="container-max">
            <div className="h-[1vh] text-center animate-pulse rounded-lg bg-gray-100 mt-5"></div>
            <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8 mt-5">
              {Array.from({ length: 3 }).map((_, i) => (
                <div
                  key={i}
                  className="h-40 w-full animate-pulse rounded-lg bg-gray-100"
                ></div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          {filteredFoodItems.length > 0 && (
            <div className="container-max">
              <div className="flex-1 flex justify-center items-center pt-3 pb-3">
                <hr className="w-1/5 border border-gray-300" />
                <h1 className=" h-[24px] mx-4 text-sm">Search Result</h1>
                <hr className="w-1/5 border border-gray-300" />
              </div>

              <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8">
                {filteredFoodItems?.map((item) =>
                  item?.menus?.map((item, i) => {
                    return <RestaurantCard key={i} item={item} />;
                  })
                )}
              </div>
            </div>
          )}

          <div className="container-max">
            <div className="flex-1 flex justify-center items-center pt-3 pb-3">
              <hr className="w-1/5 border border-gray-300" />
              <h1 className=" h-[24px] mx-4 text-sm text text-gray-600">
                Categories
              </h1>
              <hr className="w-1/5 border border-gray-300" />
            </div>

            <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8">
              {categories?.map((item, i) => (
                <CategoryCard key={i} item={item} />
              ))}
            </div>
          </div>

          {/* <div className="container-max">
            <div className="flex-1 flex justify-center items-center pt-3 pb-3">
              <hr className="w-1/5 border border-gray-300" />
              <h1 className=" h-[24px] mx-4 text-sm">Chef's Special</h1>
              <hr className="w-1/5 border border-gray-300" />
            </div>

            <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8">
              {Object.keys(chefSpecial)?.map((categoryName) =>
                itemsByCategory[categoryName]?.map((menu) =>
                  menu?.menus?.map((item, i) => {
                    return (
                      item.isSpecial && <RestaurantCard key={i} item={item} />
                    );
                  })
                )
              )}
            </div>
          </div> */}

          {Object.keys(itemsByCategory).map((categoryName) =>
            itemsByCategory[categoryName].map((menu, i) => (
              <div className="container-max" key={i}>
                <div className="flex-1 flex justify-center items-center pt-3 pb-3">
                  <hr className=" w-1/5 border border-gray-300" />
                  <h1 className="  h-[24px] mx-4 text-sm">{menu._id}</h1>
                  <hr className="w-1/5 border border-gray-300" />
                </div>

                <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8">
                  {menu?.menus?.map((item, i) => {
                    return item.isTopRated ? (
                      <RestaurantCard item={item} key={i} />
                    ) : (
                      <RestaurantCard item={item} key={i} />
                    );
                  })}
                </div>
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
};

export default RestaurantList;
