import RestaurantMenuItem from "./RestaurantMenuItem";

const RestaurantMenu = ({ menu }) => {

  return (
    <div className="my-4">
      <RestaurantMenuItem items={menu} />
    </div>
  );
};

export default RestaurantMenu;
