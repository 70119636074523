import { Link } from "react-router-dom";

const RestaurantCard = ({ item }) => {
  const info = item;

  return (
    <Link to={`/item/${item.category}`}>
      <div className="flex overlay-container justify-center ">
        <img
          src={info.thumbnail}
          alt="restaurant"
          className="relative rounded-xl object-cover w-24 aspect-square"
        />
      </div>

      <div className="flex justify-center items-center mt-2">
        <h2 className=" text-black text-[12px] ">{info?.name}</h2>
      </div>
    </Link>
  );
};

export default RestaurantCard;

//  HOC for Top Rated Restaurants
export const withTopRatedLabel = (RestaurantCard) => {
  return (props) => {
    return (
      <div className="relative">
        <p className="absolute z-10 -top-2 -left-2 rounded-md p-1 px-2 bg-zinc-900 text-white text-xs">
          Top Rated
        </p>
        <RestaurantCard {...props} />
      </div>
    );
  };
};
