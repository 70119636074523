import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decreaseItemQuantity,
  increaseItemQuantity,
  removeFromCartMultiple,
  selectItemsInCart,
} from "../features/cart/cartSlice";
import { useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
} from "@chakra-ui/react";

const RestaurantMenuItem = ({ items }) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const [showQuantityModal, setShowQuantityModal] = useState(false);
  const cartItems = useSelector(selectItemsInCart);
  const decreaseQuantity = (id) => dispatch(decreaseItemQuantity({ id }));
  const increaseQuantity = (id, quantityOption = {}) =>
    dispatch(increaseItemQuantity({ id, quantityOption }));

  const handleAddToCart = (item) => {
    dispatch(addToCart(item));
    toast.success("Added to cart!");
  };

  const handleRemoveFromCart = (item) => {
    dispatch(removeFromCartMultiple(item));
    toast.success("Item removed");
  };

  const getItemQuantityInCart = (itemId) => {
    const itemInCart = cartItems.find((item) => item.item._id === itemId);
    return itemInCart ? itemInCart.quantity : 0;
  };

  const getAItemWithQuantityOption = (foodItem, quantityOption) => {
    const itemsInCart = cartItems.filter(
      (item) => item.item._id === foodItem._id
    );

    if (!itemsInCart) {
      return 0;
    }
    let qty = 0;
    itemsInCart.forEach((itemInCart) => {
      if (
        itemInCart.item.quantityUnit === quantityOption.quantityUnit &&
        itemInCart.item.quantityQuantity === quantityOption.quantity &&
        itemInCart.item.itemPrice === quantityOption.price
      ) {
        qty = itemInCart.quantity;
      }
    });
    return qty;
  };

  const handleAddItem = (item) => {
    setSelectedItem(item);
    if (item?.quantityOptions.length === 1) {
      handleAddToCart({
        ...item,
        itemPrice: item.quantityOptions[0].price,
        quantityUnit: item.quantityOptions[0].quantityUnit,
        quantityQuantity: item.quantityOptions[0].quantity,
        quantityOptionId: item.quantityOptions[0]._id,
      });
      return;
    } else if (item?.quantityOptions.length === 0) {
      return toast.error("No Pricing Present for this item");
    } else {
      setShowQuantityModal(true);
    }
  };

  const handleAddDifferentPrice = (selectedItem, quantityOptions) => {
    handleAddToCart({
      ...selectedItem,
      itemPrice: quantityOptions.price,
      quantityUnit: quantityOptions.quantityUnit,
      quantityQuantity: quantityOptions.quantity,
      quantityOptionId: quantityOptions._id,
    });
  };
  const handleRemoveDifferentPrice = (selectedItem, quantityOptions) => {
    handleRemoveFromCart({
      ...selectedItem,
      itemPrice: quantityOptions.price,
      quantityUnit: quantityOptions.quantityUnit,
      quantityQuantity: quantityOptions.quantity,
    });
  };

  return (
    <>
      {showQuantityModal && (
        <Drawer
          isOpen={showQuantityModal}
          placement="bottom"
          onClose={() => setShowQuantityModal(false)}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody className="mt-4 mb-10 flex flex-col gap-2">
              {selectedItem?.quantityOptions?.map((quantityOption) => {
                const itemQuantityInCart = getAItemWithQuantityOption(
                  selectedItem,
                  quantityOption
                );
                return (
                  <div className="flex w-full justify-between ">
                    <div className="flex gap-4">
                      <p>{`${quantityOption.quantity} ${quantityOption.quantityUnit} - Rs.${quantityOption.price} `}</p>
                    </div>

                    {itemQuantityInCart > 0 ? (
                      <div className=" left-3 -bottom-6 flex items-center space-x-1 bg-white shadow-md rounded">
                        <button
                          onClick={() =>
                            handleRemoveDifferentPrice(
                              selectedItem,
                              quantityOption
                            )
                          }
                          className="text-orange-500  font-bold p-1 px-3 rounded-md"
                        >
                          -
                        </button>
                        <span className="font-bold  bg-white opacity-100 text-black-500">
                          {itemQuantityInCart}
                        </span>

                        <button
                          onClick={() =>
                            handleAddDifferentPrice(
                              selectedItem,
                              quantityOption
                            )
                          }
                          className="text-orange-500  font-bold p-1 px-3 rounded-md"
                        >
                          +
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() =>
                          handleAddDifferentPrice(selectedItem, quantityOption)
                        }
                        className="bg-white text-orange-500 hover:bg-orange-500 hover:text-white font-bold p-1 px-6 rounded-md  shadow-md left-[50%] -bottom-5 -translate-x-[50%]"
                      >
                        ADD
                      </button>
                    )}
                  </div>
                );
              })}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
      <h3 className="px-2 text-2xl font-bold">{items?.[0]?.category}</h3>
      <ul className="">
        {items?.map((item, i) => {
          const itemPrice = item?.quantityOptions[0]?.price;
          const itemQuantityInCart = getItemQuantityInCart(item._id);

          return (
            <li
              className="p-2 py-8 flex gap-4 md:gap-8 justify-between items-center border-b"
              key={i}
            >
              <div className="basis-8/12 space-y-2">
                <h2 className="text-base ">{item?.name}</h2>
                <p className="text-lg ">₹{itemPrice}</p>
                <p className="text-xs md:block text-zinc-500 break-words">
                  {item?.description}
                </p>
              </div>

              <div className="w-full basis-4/12 relative">
                <img
                  className="w-full h-20 aspect-video object-cover rounded-md"
                  src={item?.thumbnail}
                  alt=""
                />
                {itemQuantityInCart > 0 && item.quantityOptions.length === 1 ? (
                  <div className="absolute left-3 -bottom-6 flex items-center space-x-1 bg-white shadow-md rounded">
                    <button
                      onClick={() => decreaseQuantity(item._id)}
                      className="text-orange-500  font-bold p-1 px-3 rounded-md"
                    >
                      -
                    </button>
                    <span className="font-bold  bg-white opacity-100 text-black-500">
                      {itemQuantityInCart}
                    </span>

                    <button
                      onClick={() => increaseQuantity(item._id)}
                      className="text-orange-500  font-bold p-1 px-3 rounded-md"
                    >
                      +
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => handleAddItem(item)}
                    className="bg-white text-orange-500 hover:bg-orange-500 hover:text-white font-bold p-1 px-6 rounded-md absolute shadow-md left-[50%] -bottom-5 -translate-x-[50%]"
                  >
                    ADD
                  </button>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};
export default RestaurantMenuItem;
