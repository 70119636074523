import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAllItemsByCondition } from "../services/menuApi";
import { getErrorMessage } from "../functions";

const useRestaurantsMenu = (restId) => {
  const [restaurant, setRestaurant] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [restaurantId, setRestaurantId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("restaurant");
    setRestaurantId(id);
  }, [location.search]);

  useEffect(() => {
    if (!restId || !restaurantId) {
      // toast.error("Please select a category");
      return;
    }

    const fetchMenusItems = async () => {
      try {
        const res = await getAllItemsByCondition(
          `?category=${restId}&restaurant=${restaurantId}`
        );
        setRestaurant(res);
      } catch (error) {
        setError(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchMenusItems();
  }, [restId, restaurantId]);

  return { restaurant, isLoading, error };
};

export default useRestaurantsMenu;
