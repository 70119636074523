import { Link } from "react-router-dom";
import res from "./Restaurant.png";
import img1 from "../pages/Img1.jpg";

const Intro = () => {
  return (
    <div className="flex flex-col justify-center items-center bg-[#F28500] min-h-screen">
      <div className="text-center">
        <img src={res} alt="Image 1" className="mx-auto w-[100px]" />
        <p className="mt-4">Restaurant's Name</p>
      </div>

      <div className="flex flex-col justify-center items-center ">
        <img src={img1} alt="Image 2" className="mx-auto w-[500px] h-[304px]" />
        <p className="mt-4">Hungry???</p>
        <div className="mt-5 px-4 py-2 bg-[#333131] w-44 text-white rounded-3xl">
          <Link>Browse Menu</Link>
        </div>
      </div>
    </div>
  );
};

export default Intro;
