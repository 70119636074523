import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CartItemList from "../components/CartItemList";
import OrderSummary from "../components/OrderSummary";
import { selectItemsInCart } from "../features/cart/cartSlice";
import { IoReturnUpBackOutline } from "react-icons/io5";
const Cart = () => {
  const navigate = useNavigate();
  const cartItems = useSelector(selectItemsInCart);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});

  const handleCloseOrderModal = () => {
    setShowConfirmation(false);
    navigate("/invoice");
  };
  return (
    <div className="container-max py-8 pb-16 ">
      <div className="flex gap-2 items-center">
        <IoReturnUpBackOutline size={34} onClick={() => navigate("/")} />
        <h1 className="text-2xl">Cart</h1>
      </div>

      {/* cart details */}
      <div className="min-h-[60vh] pb-8 md:flex gap-8">
        {/* cart items */}
        <CartItemList />
        {/* order summary */}
        {cartItems && cartItems.length !== 0 && (
          <OrderSummary
            setShowConfirmation={setShowConfirmation}
            setOrderDetail={setOrderDetail}
          />
        )}
      </div>

      {showConfirmation && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-md shadow-md flex flex-col items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-16 h-16 text-green-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 19a9 9 0 110-18a9 9 0 010 18zM4.293 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 00-1.414-1.414L8 12.586l-3.293-3.293z"
                clipRule="evenodd"
              />
            </svg>
            <h2 className="text-xl font-bold mb-4">
              Order Placed Successfully
            </h2>
            <p>Thank you for shopping with us!</p>
            <p>
              Order no: {orderDetail?._id} <br />
              Your food will arrive in 25 mins....
            </p>
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md"
              onClick={handleCloseOrderModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
