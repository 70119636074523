import http from "./http";

const integrationApi = "/payment";

// Integration API functions
export const capturePayment = async (data) => {
  return await http.post(`/webhook/customer/capture`, data);
};
// Integration API functions
export const payForOrder = (data, query = "") => {
  return http.post(`${integrationApi}/customer/pay${query}`, data);
};
// Integration API functions
export const payForOrderInCash = (data, query = "") => {
  return http.post(`${integrationApi}/customer/cash/pay${query}`, data);
};

export const getRestaurantSecrets = (query = "") => {
  return http.get(`restaurant-integration${query}`);
};
