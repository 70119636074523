import React, { useEffect, useLayoutEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import useOnlineStatus from "./hooks/useOnlineStatus";
import { useDispatch, useSelector } from "react-redux";
import { closeMenu } from "./features/app/appSlice";
import Header from "./components/Header";
import Home from "./pages/Home";
import Cart from "./pages/Cart";
import Restaurant from "./pages/Item";
import Intro from "./pages/Intro";
import FootNav from "./pages/FootNav";
import Error from "./pages/Error";
import Loader from "./components/Loader";
import { getRestaurantByCondition } from "./services/restaurantApi";
import Invoice from "./pages/Invoice";

const App = () => {
  const isOnline = useOnlineStatus();
  const { isMenuOpen } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const [restaurant, setRestaurant] = useState("");
  const [tableNo, setTableNo] = useState("");

  const getSearchParams = (url) => {
    const params = new URLSearchParams(url);
    const restaurant = params.get("restaurant");
    const tableNo = params.get("tableNo");
    return { restaurant, tableNo };
  };

  useEffect(() => {
    const { restaurant: restaurantFromUrl, tableNo: tableNoFromUrl } =
      getSearchParams(location.search);
    if (!restaurantFromUrl || !tableNoFromUrl) {
      navigate({
        pathname: location.pathname,
        search: `?restaurant=${restaurant}&tableNo=${tableNo}`,
      });
    } else {
      setRestaurant(restaurantFromUrl);
      setTableNo(tableNoFromUrl);
    }
  }, [location.search, navigate, restaurant, tableNo]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    isMenuOpen && dispatch(closeMenu());
  }, [dispatch, isMenuOpen]);

  useEffect(() => {
    const fetchRestaurant = async () => {
      if (!restaurant) {
        return;
      }
      const query = `?_id=${restaurant}`;
      return await getRestaurantByCondition(query);
    };

    const fetchData = async () => {
      try {
        await fetchRestaurant();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [restaurant]);

  useEffect(() => {
    // Store restaurant and table number in local storage
    localStorage.setItem("restaurant", restaurant);
    localStorage.setItem("tableNo", tableNo);
    // Empty the cart
    localStorage.removeItem("cart");
  }, [restaurant, tableNo]);

  if (loading) return <Loader />;
  return (
    <>
      <Toaster />
      <Header tableNo={tableNo} restaurant={restaurant} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="cart" element={<Cart />} />
        <Route path="item/:id" element={<Restaurant />} />
        <Route path="Intro" element={<Intro />} />
        <Route path="invoice" element={<Invoice />} />
        <Route path="FootNav" element={<FootNav />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <FootNav />
      {!isOnline && (
        <div className="flex flex-col justify-center items-center min-h-screen">
          <h1 className="text-4xl font-bold">Oops! Connection lost</h1>
          <p>
            Looks like you're offline, please check your internet connection.
          </p>
        </div>
      )}
    </>
  );
};

export default App;
