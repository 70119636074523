import axios from "axios";
const baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;
// const baseURL ="https://qr-restaurant-backend.vercel.app/api"

const axiosInstance = axios.create({
  baseURL,
});

// State to manage loading state
let requestCount = 0;

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Increment request count
    requestCount++;

    // Show loader
    if (requestCount === 1) {
      document.body.classList.add("loading");
    }

    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Decrement request count
    requestCount--;

    // Hide loader
    if (requestCount === 0) {
      document.body.classList.remove("loading");
    }

    return response.data;
  },
  (error) => {
    requestCount--;

    // Hide loader
    if (requestCount === 0) {
      document.body.classList.remove("loading");
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
