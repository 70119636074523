import http from "./http";

const restaurantApi = "/restaurant/menu";
const categoriesApi = "/restaurant/menu/category";

export const getAllItemsByCondition = async (query = "") => {
  const res = await http.get(
    `${restaurantApi}/customer/category/items${query}`
  );
  return res;
};


export const getChefSpecial = async (query = "") => {
  const res = await http.get(`${restaurantApi}/customer/special/${query}`);
  return res;
};

export const getTopRated = async (query = "") => {
  const res = await http.get(`${restaurantApi}/customer/top-rated/${query}`);
  return res;
};



export const getAllCategories = async (query) => {
  const res = await http.get(`${categoriesApi}/customer/all${query}`);
  return res;
};
